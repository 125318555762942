/**
 * Constants
 *
 * Enums are defined on the data model of the backend and have to be maintained in sync here.
 * https://docs.google.com/spreadsheets/d/145jgl0V-KQW2aj1Mm5ZjIU0yH3ggxBpNwQKyhS6Hb5M/edit#gid=0
 */

export const VERSION = '4.8.2'; // should be the same as in backend/setup.py
export const PAGING_OPTIONS = [50, 100, 300];

// backend/tscloud/models.py:JobStatus
export const LABELLER_LINK_GDRIVE =
  'https://drive.google.com/drive/u/1/folders/16OLbmgolLGe9HVZ6CONShrRVoOOmkO4p';

export const JobStatus = {
  PENDING: 0,
  INPROGRESS: 1,
  DONE: 2,
  FAILED: 3,
};

export const JobStatusToStringMap = {
  [JobStatus.PENDING]: 'Pending',
  [JobStatus.INPROGRESS]: 'In Progress',
  [JobStatus.DONE]: 'Done',
  [JobStatus.FAILED]: 'Failed',
};

// backend/tscloud/models.py:JobType
export const JobType = {
  DEFAULT: 0,
  AUTOLABEL: 1,
  SEQUENCE_SPLIT: 2,
  DICOM_CONVERSION: 3,
  PREPROCESSING: 4,
};

// backend/tscloud/models.py:MetadataDVTDatatype
export const MetadataDVTDatatype = {
  // Generic
  HEALTHY: 0,
  PATHOLOGICAL: 1,
  BACKGROUND: 2,
  INVALID: 10,

  // DVT
  INVALID_COMPRESSION: 3,
  NOT_CLOSING: 4,
  SLIDING: 5,
  COMPRESSION_1: 6,
  COMPRESSION_2: 7,
  COMPRESSION_3: 8,
  VESSEL: 9,

  // Wrist
  BONE: 11,
};

export const MetadataDVTDatatypeToStringMap = {
  [MetadataDVTDatatype.HEALTHY]: 'healthy',
  [MetadataDVTDatatype.PATHOLOGICAL]: 'pathological',
  [MetadataDVTDatatype.BACKGROUND]: 'background',
  [MetadataDVTDatatype.INVALID]: 'invalid',

  [MetadataDVTDatatype.INVALID_COMPRESSION]: 'invalid_compression',
  [MetadataDVTDatatype.NOT_CLOSING]: 'not_closing',
  [MetadataDVTDatatype.SLIDING]: 'sliding',
  [MetadataDVTDatatype.COMPRESSION_1]: 'compression_1',
  [MetadataDVTDatatype.COMPRESSION_2]: 'compression_2',
  [MetadataDVTDatatype.COMPRESSION_3]: 'compression_3',
  [MetadataDVTDatatype.VESSEL]: 'vessel',

  [MetadataDVTDatatype.BONE]: 'bone',
};

// backend/tscloud/models.py:UserRole
export const UserRole = {
  ADMIN: 4,
  SUPERVISOR: 2,
  LABELLER: 1,
};

export const UserRoleToUrlMap = {
  [UserRole.ADMIN]: '/dataCurationDashboard', // FIXME: location will change
  [UserRole.SUPERVISOR]: '/supervisorDashboard',
  [UserRole.LABELLER]: '/labellerDashboard',
};

// backend/tscloud/models.py:ScanType
export const ScanType = {
  DVT: 0,
  WRIST: 1,
};

export const ScanTypeToStringMap = {
  [ScanType.DVT]: 'DVT',
  [ScanType.WRIST]: 'WRIST',
};

// backend/tscloud/models.py:LabelStatus
export const LabelStatus = {
  REVIEW: 0,
  ACCEPTED: 1,
  REJECTED: 2,
  AUTOLABEL: 3,
  PENDING: 4,
};

export const LabelStatusMapToStringMap = {
  [LabelStatus.REVIEW]: 'REVIEW',
  [LabelStatus.ACCEPTED]: 'ACCEPTED',
  [LabelStatus.REJECTED]: 'REJECTED',
  [LabelStatus.AUTOLABEL]: 'AUTOLABEL',
  [LabelStatus.PENDING]: 'PENDING',
};

export const LabelStatusToStringMap = {
  [LabelStatus.REVIEW]: 'SUBMITTED LABEL',
  [LabelStatus.ACCEPTED]: 'ACCEPTED LABEL',
  [LabelStatus.REJECTED]: 'REJECTED LABEL',
  [LabelStatus.AUTOLABEL]: 'FAST LABELLING',
};

// backend/tscloud/models.py:TaskStatus
export const TaskStatus = {
  TODO: 0,
  PENDING: 1,
  FINISHED: 2,
};

// backend/tscloud/models.py:NotificationType
export const NotificationType = {
  REVIEW: 0,
  COMMENT: 1,
};

// backend/tscloud/models.py:Device
export const Device = {
  LUMIFY: 0,
  CLARIUS: 1,
  CLARIUS_HD: 2,
  VSCAN: 3,
  PHILIPS: 4,
  STATIONARY_PHILIPS: 5,
  INVALID: 6,
  GE_VENUE: 7,
};

export const DeviceToStringMap = {
  [Device.LUMIFY]: 'Lumify',
  [Device.CLARIUS]: 'Clarius',
  [Device.CLARIUS_HD]: 'Clarius HD',
  [Device.VSCAN]: 'VScan',
  [Device.PHILIPS]: 'Philips',
  [Device.STATIONARY_PHILIPS]: 'Stationary Philips',
  [Device.INVALID]: 'Invalid',
  [Device.GE_VENUE]: 'GE Venue',
};

// backend/tscloud/models.py:Region
export const Region = {
  // Generic
  INVALID: 4,

  // DVT
  GROIN: 0,
  THIGH: 1,
  KNEE: 2,
  CALF: 3,

  // WRIST
  ULNA_DORSAL: 5,
  ULNA_ULNAR: 6,
  ULNA_PALMAR: 7,
  RADIUS_DORSAL: 8,
  RADIUS_RADIAL: 9,
  RADIUS_PALMAR: 10,
};

export const RegionToStringMap = {
  // Generic
  [Region.INVALID]: 'Invalid',

  // DVT
  [Region.GROIN]: 'Groin',
  [Region.THIGH]: 'Thigh',
  [Region.KNEE]: 'Knee',
  [Region.CALF]: 'Calf',

  // WRIST
  [Region.ULNA_DORSAL]: 'Ulna Dorsal',
  [Region.ULNA_ULNAR]: 'Ulna Ulnar',
  [Region.ULNA_PALMAR]: 'Ulna Palmar',
  [Region.RADIUS_DORSAL]: 'Radius Dorsal',
  [Region.RADIUS_RADIAL]: 'Radius Radial',
  [Region.RADIUS_PALMAR]: 'Radius Palmar',
};

// backend/tscloud/models.py:ExamDatatype
export const ExamDatatype = {
  HEALTHY: 0,
  PATHOLOGICAL: 1,
  UNKNOWN: 2,
};

export const ExamDatatypeToStringMap = {
  [ExamDatatype.HEALTHY]: 'healthy',
  [ExamDatatype.PATHOLOGICAL]: 'pathological',
  [ExamDatatype.UNKNOWN]: 'unknown',
};

export const ANATOMY_CATEGORIES = [
  'BACKGROUND',
  'INVALID',
  'ATA + ATV',
  'PTA + PTV',
  'FBA + FBV',
  'ATA/PTA + ATV/PTV',
  'ATA/FBA + ATV/FBV',
  'PTA/FBA + PTV/FBV',
  'ATA/PTA/FBA + ATV/PTV/FBV',
  'EIV + EIA',
  'GSV / CFV + CFA',
  'GSV / CFV + SFA / PFA',
  'CFV + CFA',
  'CFV + CFA -> SFA / PFA',
  'CFV + SFA / PFA',
  'SFV / PFV + SFA / PFA',
  'SFV + SFA',
  'SFV (x2) + SFA',
  'SFV (x3) + SFA',
  'POPV',
  'POP-TRUNK',
  'POP-TRI',
  'POP, POP-TRUNK',
  'POP, POP-TRI',
  'POP, POP-TRUNK, POP-TRI',
  'POP-TRUNK, POP-TRI',
  'ULNA DORSAL',
  'ULNA ULNAR',
  'ULNA PALMAR',
  'RADIUS DORSAL',
  'RADIUS RADIAL',
  'RADIUS PALMAR',
];

export const LEG_CATEGORIES = ['right', 'left'];
export const WRIST_CATEGORIES = ['right', 'left'];

export const LANDMARK_OPTIONS = [
  'CALF_ANTERIOR_WINDOW',
  'CALF_POSTERIOR_FIBULA_WINDOW',
  'CALF_POSTERIOR_TIBIA_WINDOW',
  'CL1',
  'CL2',
  'CL3',
  'CL4',
  'CL5',
  'CL6',
  'CL7',
  'CL8',
  'LM0',
  'LM1',
  'LM2',
  'LM3',
  'LM4',
  'LM5',
  'LM6',
  'LM7',
  'LM8',
  'LM9',
  'LM10',
  'BACKGROUND',
  'ULNA_DORSAL',
  'ULNA_ULNAR',
  'ULNA_PALMAR',
  'RADIUS_DORSAL',
  'RADIUS_RADIAL',
  'RADIUS_PALMAR',
];

export const LoadingStatus = {
  IDLE: 'idle',
  PENDING: 'pending',
  DONE: 'done',
  FAILED: 'failed',
};
